import React from 'react';
import { Checkbox } from '../controls/Checkbox';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { TradeSide } from '../../types/trades/TradeSide';
import { Preloader } from '../common/Preloader';

interface Props {
    vconBuy: boolean;
    vconSell: boolean;
    tradeSide: TradeSide;
    onClick: (tradeId: string, isBuyTrade: boolean, value: boolean) => void;
    tradeId: string;
}

export const VconCheckbox: React.FC<Props> = ({ onClick, tradeId, vconBuy, vconSell, tradeSide }) => {
    const isBuyTrade = tradeSide === TradeSide.Buy;
    const isRequesting = useSelector((state: AppState) => state.blotter.requestStatusSetVcon[`${tradeId}_${isBuyTrade}`]);

    const handleClick = () => {
        if (!isRequesting) {
            onClick(tradeId, isBuyTrade, isBuyTrade ? !vconBuy : !vconSell)
        }
    };

    return (
        <Preloader small inProgress={isRequesting}>
            <Checkbox
                checked={isBuyTrade ? vconBuy : vconSell}
                onChange={handleClick}
            />
        </Preloader>
    )
};
