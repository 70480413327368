import * as React from 'react';
import { useDispatch } from 'react-redux';
import { tradeAllocationTemplateActions } from '../../../../actions/trade-allocation-template.actions';
import { RequestState } from "../../../../constants/request-state";
import { tradeAllocationTemplateService } from '../../../../services/trade-allocation-template.service';
import { errorActions } from '../../../../actions/error.actions';

export function useTempateDelete() {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = React.useState(RequestState.none);

    const onDelete = async (templateId: number) => {
        try {
            setRequestState(RequestState.request);
            await tradeAllocationTemplateService.deleteTemplate(templateId);
            setRequestState(RequestState.success);
            dispatch(tradeAllocationTemplateActions.deleteTemplate(templateId));
        } catch (e) {
            dispatch(errorActions.unexpectedError(e));
            setRequestState(RequestState.failure);
        }
    };

    return { deleteRequestState: requestState, onDelete };
}
