import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { sumBy, values } from 'lodash';
import { useTradeAllocation } from '../../../effects/data-accessors/useTradeAllocation';
import Popup from '../../controls/Popup';
import { PopupBody } from '../../controls/PopupBody';
import { PopupFooter } from '../../controls/PopupFooter';
import { Preloader } from '../../common/Preloader';
import { isRequestSuccess, isRequesting } from '../../../utils/request-state.utils';
import { gridActions } from '../../../actions/grid.actions';
import { gridColumns } from '../../../constants/grid.columns';
import { GridColumn, GridDataItem } from '../../../types/state/GridState';
import { EditTradeAllocationItem } from '../../../types/state/entities/TradeAllocationState';
import { AppState } from '../../../types/state/AppState';
import { TradeAllocationItem } from '../../../types/trade-allocation/TradeAllocationItem';
import { Grid } from '../../grid/Grid';
import { BlotterSearchResult } from '../../../types/blotter/BlotterSearchResult';
import { moneyUtils } from '../../../utils/money.utils';
import { numericUtils } from '../../../utils/numeric.utils';
import { UnsavedChangesPopup } from '../../routing/UnsavedChangesPopup'
import RouteLeavingGuard from '../../routing/RouteLeavingGuard';
import { blotterTradeAllocationActions } from '../../../actions/blotter-trade-allocation.actions';
import { SettlementAccount } from '../../../types/settlement/SettlementAccount';
import { user } from '../../../user';
import { roles } from '../../../constants';
import { formatUtils } from '../../../utils';
import { LocationState, LocationStateBuilder, PopupType } from '../../../types/state/ui/LocationState';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';
import { TradeSettlementStatus } from '../../../types/settlement/TradeSettlementStatus';
import { TradeSide } from '../../../types/trades/TradeSide';
import { useAppDispatch } from '../../../effects/useAppDispatch';

interface Props {
    settlementAccounts: SettlementAccount[];
}

export type TradeAllocationPopupLocationPayload = {
    tradeAllocationTradeId: string;
    side: TradeSide;
    companyId: number;
}

export function TradeAllocationPopup({ settlementAccounts }: Props) {
    const locationState = useLocation<LocationState<any, TradeAllocationPopupLocationPayload>>().state;
    const trade = useSelector((s: AppState) =>
        locationState?.popup?.type === PopupType.TradeAllocationPopup &&
        locationState?.popup?.payload?.tradeAllocationTradeId &&
        s.blotter.securities.find(t => t.tradeId === locationState?.popup?.payload?.tradeAllocationTradeId)
    );

    if (!trade || !locationState?.popup?.payload?.companyId || !locationState?.popup?.payload?.side) {
        return null;
    }

    return (
        <TradeAllocationPopupContent
            trade={trade}
            side={locationState!.popup!.payload!.side}
            settlementAccounts={settlementAccounts.filter(a =>
                a.assetManager.id === locationState!.popup!.payload!.companyId)}
        />
    );
}

interface ContentProps {
    side: TradeSide;
    trade: BlotterSearchResult;
    settlementAccounts: SettlementAccount[];
}

const TOTAL_TRADE_AMOUNT_ERROR = 'Entered amounts of trade should be equal to the total traded amount'
const TOTAL_TRADE_PERCENT_ERROR = 'The sum of all percentages of trade should be equal 100%.'

function TradeAllocationPopupContent({ trade, settlementAccounts, side }: ContentProps) {
    const dispatch = useAppDispatch();
    const history = useHistory<LocationState>();

    const [unsavedChangesAlertVisible, setUnsavedChangesAlertVisible] = React.useState(false);
    const [amountError, setAmountError] = React.useState(false);

    const tradeAllocationState = useTradeAllocation(trade.tradeId);
    const requestState = tradeAllocationState.requestState;
    const tradeAllocationItems = React.useMemo(
        () => tradeAllocationState.tradeAllocationItems.filter(i => i.side === side),
        [side, tradeAllocationState]
    );

    const gridMountedKey = useSelector((s: AppState) => s.grid.mountedKey);
    const saveTradeAllocationRequestState = useSelector((s: AppState) => s.blotter.requestStateSaveTradeAllocation);
    const dataItems = useSelector((s: AppState) => s.grid.dataItems);

    const editTradeAllocationItems: GridDataItem<EditTradeAllocationItem>[] = React.useMemo(() => dataItems.filter(i => !i.draft), [dataItems]);
    const hasErrors = React.useMemo(() => editTradeAllocationItems.some(i => i.errors && values(i.errors).length), [editTradeAllocationItems]);
    const isSeller = user.hasRoles(...roles.seller());
    const isBd = user.hasRoles(...roles.bd());
    const isProceedsVisible = side === TradeSide.Sell;
    const requesting = isRequesting(saveTradeAllocationRequestState) || isRequesting(requestState);
    const { percent, amount, proceeds } = React.useMemo(() => {
        const percent = sumBy(editTradeAllocationItems, i => numericUtils.numberOrDefault(i.percent));
        const amount = sumBy(editTradeAllocationItems, i => numericUtils.numberOrDefault(i.amount));
        const proceeds =
            isProceedsVisible
                ? sumBy(editTradeAllocationItems, i => numericUtils.numberOrDefault(i.proceed))
                : 0;

        return {
            percent: numericUtils.round(percent, 2),
            amount: numericUtils.round(amount, 3),
            proceeds: numericUtils.round(proceeds, 3)
        };
    }, [editTradeAllocationItems, isProceedsVisible]);


    React.useEffect(() => {
        if (amountError && amount === trade.size) {
            setAmountError(false);
        }
    }, [trade, amount, amountError]);

    React.useEffect(() => {
        return () => {
            // reset when on mount
            dispatch(blotterTradeAllocationActions.resetPopupState());
            dispatch(gridActions.reset());
        };
    }, [dispatch]);

    React.useEffect(() => {
        // Initialize Grid
        if (isRequestSuccess(requestState) && !gridMountedKey) {
            const isTotalTradeAllocationValid = (dataItems: GridDataItem<EditTradeAllocationItem>[]) => {
                const amount = sumBy(dataItems, i => numericUtils.numberOrDefault(i.amount));
                return amount === trade.size
            }

            const settlementAccountColumn: GridColumn = {
                ...gridColumns.settlementAccount as GridColumn,
                selectSourceItemsCallback: () => settlementAccounts.map(a => ({
                    key: a.id, title: a.accountName
                }))
            };
            const percentOfTradeColumn: GridColumn = {
                ...gridColumns.percentOfTrade as GridColumn,
                validate: (value, __, dataItems: GridDataItem<EditTradeAllocationItem>[]) => {
                    if (
                        !numericUtils.isNumber(value) ||
                        +value < gridColumns.percentOfTrade.min ||
                        +value > gridColumns.percentOfTrade.max
                    ) {
                        return ''
                    }
                    return isTotalTradeAllocationValid(dataItems)
                        ? ""
                        : TOTAL_TRADE_PERCENT_ERROR
                },
                updateDependencyColumnsCallback: (rowIndex: number) => {
                    dispatch(blotterTradeAllocationActions.recalculateAmountOfTrade(rowIndex, trade.tradeId));
                    return { type: 'AMOUNT_CHANGED' };
                }
            };
            const amountOfTradeColumn: GridColumn = {
                ...gridColumns.amountOfTrade as GridColumn,
                validate: (_, __, dataItems: GridDataItem<EditTradeAllocationItem>[]) => {
                    return isTotalTradeAllocationValid(dataItems)
                        ? ""
                        : TOTAL_TRADE_AMOUNT_ERROR
                },
                updateDependencyColumnsCallback: (rowIndex: number) => {
                    dispatch(blotterTradeAllocationActions.recalculatePercentOfTrade(rowIndex, trade.tradeId));
                    return { type: 'PERCENT_CHANGED' };
                }
            };
            const columns = [settlementAccountColumn, percentOfTradeColumn, amountOfTradeColumn];

            if (isProceedsVisible) {
                const proceedsColumn: GridColumn = {
                    ...gridColumns.proceeds as GridColumn,
                    readonly: isSeller
                };

                columns.push(proceedsColumn);
            }

            const rowLimit = 20;

            const editItems: EditTradeAllocationItem[] = tradeAllocationItems.map(i => ({
                id: i.id,
                settlementAccountId: i.settlementAccount.id,
                percent: i.percent,
                amount: i.amount,
                proceed: i.proceed
            }));
            dispatch(gridActions.init(editItems, columns, rowLimit));
        }
    }, [requestState, tradeAllocationItems, trade, settlementAccounts, gridMountedKey, isSeller, isProceedsVisible, dispatch]);

    const hasChanges = () => {
        const isChangedOrRemoved = (original: TradeAllocationItem, edit?: EditTradeAllocationItem) => {
            if (!edit) return true;

            const originProceed = original.proceed == null ? -1 : original.proceed;
            const editProceed = edit.proceed == null || edit.proceed === '' ? -1 : Number(edit.proceed);

            return (
                original.amount !== moneyUtils.parse(String(edit.amount)) ||
                original.percent !== Number(edit.percent) ||
                originProceed !== editProceed ||
                original.settlementAccount.id !== edit.settlementAccountId
            );
        }

        return (
            tradeAllocationItems.length !== editTradeAllocationItems.length ||
            editTradeAllocationItems.some(i => !i.id) || // new items added
            tradeAllocationItems.some(i => isChangedOrRemoved(i, editTradeAllocationItems.find(e => e.id === i.id)))
        );
    }

    const canSaveUnchanged = () => {
        const settlementStatus = side === TradeSide.Buy ? trade.sellerSettlementStatus : trade.buyerSettlementStatus;
        if (isBd && settlementStatus === TradeSettlementStatus.PendingProceeds) {
            return true;
        }

        return hasChanges();
    }

    const handleSave = () => {
        if (amount !== trade.size) {
            setAmountError(true);
            dispatch(gridActions.validate());
        } else {
            dispatch(blotterTradeAllocationActions.save(trade.tradeId, trade.allocationLock, side));
        }
    }

    const handleClose = () => {
        if (!requesting) {
            history.replace({
                ...history.location,
                state: new LocationStateBuilder(history.location.state).resetPopup().result()
            });
        }
    }

    const renderTitle = () =>
        <h1>
            Trade Allocation
            <span className="name-tag">{trade.ticker}</span>
        </h1>

    const renderGridFooter = React.useCallback(() => {
        return (
            <tr>
                <td className="cell-no-data"></td>
                <td className="cell-no-data cell-total">
                    <span className="text-bold">Total</span>
                </td>
                <td className="cell-settlementAccountId"></td>
                <td className="cell-percent">
                    {formatUtils.formatDecimal(percent, 2)}% / <span className="text-bold">100.00%</span>
                </td>
                <td className="cell-amount">
                    {moneyUtils.money(amount, true, 3)} / <span className="text-bold">{moneyUtils.money(trade.size, true, 3)}</span>
                </td>
                {
                    isProceedsVisible &&
                    <td className="cell-proceed">{moneyUtils.money(proceeds, true, 3)}</td>
                }
                <td className="cell-no-data cell-no-data-end"></td>
            </tr>
        );
    }, [percent, amount, proceeds, isProceedsVisible, trade]);

    return (
        <>
            {
                unsavedChangesAlertVisible &&
                <UnsavedChangesPopup
                    onConfirm={handleClose}
                    onCancel={() => setUnsavedChangesAlertVisible(false)}
                />
            }
            <RouteLeavingGuard
                navigate={(pathname: string, search?: string, state?: any) =>
                    history.replace({ pathname, search, state })
                }
                shouldBlockNavigation={() =>
                    !isRequesting(saveTradeAllocationRequestState) &&
                    !isRequestSuccess(saveTradeAllocationRequestState) &&
                    hasChanges()
                }
            />
            <Popup
                modalClass="modal-trade-allocation"
                renderTitle={renderTitle()}
                renderInBody={true}
                onClose={handleClose}
            >
                <Preloader inProgress={!isRequestSuccess(requestState)}
                >
                    <PopupBody className="trade-allocation container-flex">
                        <Grid
                            dataUploadDisabled={true}
                            addRowVisible={false}
                            moveRowVisible={false}
                            renderFooter={renderGridFooter}
                        />
                        {
                            amountError &&
                            <StatusMessageSection type={StatusMessageSectionType.Error}>
                                <li>{TOTAL_TRADE_AMOUNT_ERROR}</li>
                                <li>{TOTAL_TRADE_PERCENT_ERROR}</li>
                            </StatusMessageSection>
                        }
                    </PopupBody>
                    <PopupFooter>
                        <button
                            className="btn btn-ghost"
                            disabled={requesting}
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-main"
                            disabled={
                                requesting ||
                                !editTradeAllocationItems.length ||
                                hasErrors ||
                                amountError ||
                                !canSaveUnchanged()
                            }
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </PopupFooter>
                </Preloader>
            </Popup>
        </>
    );
}
