import * as React from 'react'
import { useHistory, useLocation } from 'react-router';
import { TradeAllocationTemplate } from '../../../../types/trade-allocation/template/TradeAllocationTemplate';
import { RightSidePanel } from '../../../controls/RightSidePanel';
import { EmptyPlaceholder } from '../../../common/EmptyPlaceholder';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../types/state/AppState';
import { TemplateList } from './TemplateList';
import { TemplateEdit } from './TemplateEdit';
import { LocationState, LocationStateBuilder, PanelType } from '../../../../types/state/ui/LocationState';
import { blotterTradeAllocationTemplateActions } from '../../../../actions/blotter-trade-allocation-template.actions';
import { isRequesting, isRequestSuccess } from '../../../../utils';
import { OnHoverTooltip } from '../../../common';
import { useSettlementAccounts } from '../../../../effects/data-accessors/useSettlementAccounts';
import IconSVG from "../../../../styles/svg-icons";
import { FeatureButton } from '../../../access/FeatureButton';
import { RoleActionBlocker } from '../../../access/RoleActionBlocker';
import { roles } from '../../../../constants/roles';

interface Props {
    templates: TradeAllocationTemplate[];
}

const minSettlementAccounts = 2;
const maxTemplates = 20;

export function TradeAllocationTemplatePanel({ templates }: Props) {
    const locationState = useLocation<LocationState<{ editTemplateId?: number }>>().state;

    if (locationState?.panel?.type !== PanelType.Templates) return null;

    return <TradeAllocationTemplatePanelContent
        templates={templates}
        editTemplateId={locationState?.panel?.payload?.editTemplateId}
    />
}

function TradeAllocationTemplatePanelContent({ templates, editTemplateId }: Props & { editTemplateId?: number }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const savingState = useSelector((s: AppState) => s.blotter.tradeAllocationTemplate.saveRequestState);
    const { settlementAccounts, settlementAccountsRequestState } = useSettlementAccounts(false);

    const isCreate = editTemplateId === 0;
    const isEdit = Boolean(editTemplateId);
    const editTemplate = isEdit ? templates.find(t => t.id === editTemplateId) : undefined;

    const notEnoughSettlementAccounts =
        !isRequestSuccess(settlementAccountsRequestState) ||
        settlementAccounts.length < minSettlementAccounts;

    React.useEffect(() => {
        return () => {
            dispatch(blotterTradeAllocationTemplateActions.resetPanelState());
        };
    }, [dispatch]);


    const handleClose = () => {
        if (!isRequesting(savingState)) {
            history.replace({ ...history.location, state: undefined });
        }
    }

    const renderTitle = () => {
        return (
            <>
                Allocation Templates
                <OnHoverTooltip
                    overlay="To use allocation templates, you must have at least two settlement accounts."
                    disabled={!notEnoughSettlementAccounts}
                >
                    <OnHoverTooltip
                        overlay="The maximum number of templates has been reached"
                        disabled={notEnoughSettlementAccounts || templates.length < maxTemplates}
                    >
                        <RoleActionBlocker roles={[roles.SellerTrader]}>
                            <FeatureButton
                                className="btn btn-sm btn-ghost"
                                disabled={
                                    isCreate ||
                                    isEdit ||
                                    !isRequestSuccess(settlementAccountsRequestState) ||
                                    settlementAccounts.length < minSettlementAccounts ||
                                    templates.length >= maxTemplates
                                }
                                onClick={() => history.replace({
                                    ...history.location,
                                    state: new LocationStateBuilder<{ editTemplateId?: number }>()
                                        .panel(PanelType.Templates, { editTemplateId: 0 })
                                        .result()
                                })}
                            >
                                <IconSVG name="plus" width="16" height="16" />
                                <span>Template</span>
                            </FeatureButton>
                        </RoleActionBlocker>
                    </OnHoverTooltip>
                </OnHoverTooltip>
            </>
        );
    }

    return (
        <RightSidePanel
            className="right-panel-templates"
            title={renderTitle()}
            onClose={handleClose}
        >
            {
                !templates.length &&
                editTemplateId == null &&
                <EmptyPlaceholder textView text="All created allocation templates will be appeared here." />
            }

            {!isCreate && !isEdit && templates.length > 0 && <TemplateList templates={templates} />}
            {
                (isCreate || isEdit) &&
                <TemplateEdit
                    template={editTemplate}
                    settlementAccounts={settlementAccounts}
                    existTemplateNames={
                        templates
                            .filter(t => t.id !== editTemplateId)
                            .map(t => t.name)
                    }
                />
            }
        </RightSidePanel>
    );
}
