import * as React from 'react';
import { TradeAllocationTemplate } from '../../../../types/trade-allocation/template/TradeAllocationTemplate';
import { TemplateListItem } from './TemplateListItem';
import { useTempateActiveChange } from './useTemplateActiveChange';
import { useTempateDelete } from './useTemplateDelete';
import { isRequesting } from '../../../../utils/request-state.utils';

interface Props {
    templates: TradeAllocationTemplate[];
}

export function TemplateList({ templates }: Props) {
    const { deleteRequestState, onDelete } = useTempateDelete();
    const { activateRequestState, onActiveChange } = useTempateActiveChange();

    return (
        <div className="templates-list">
            {
                templates.map(t =>
                    <TemplateListItem
                        key={t.id}
                        template={t}
                        disabled={isRequesting(deleteRequestState) || isRequesting(activateRequestState)}
                        onActiveChange={onActiveChange}
                        onDelete={onDelete}
                    />
                )
            }
        </div>
    );
}

