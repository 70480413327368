import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import cn from 'classnames';
import { isRequestSuccess } from '../../../../utils/request-state.utils';
import { RequestState } from '../../../../constants/request-state';
import { TradeAllocationTemplate } from '../../../../types/trade-allocation/template/TradeAllocationTemplate';
import { LocationState, LocationStateBuilder, PanelType } from '../../../../types/state/ui/LocationState';
import { OnHoverTooltip } from '../../../common';
import { TooltipPlacement } from '../../../common/Tooltip';
import { useSettlementAccounts } from '../../../../effects/data-accessors/useSettlementAccounts';
import IconSVG from '../../../../styles/svg-icons';

interface Props {
    templates: TradeAllocationTemplate[];
    requestState: RequestState;
    onShow: (location: LocationState<any, any>) => void;
    disabled?: boolean;
}

export function TradeAllocationTemplateButton({ templates, requestState, onShow, disabled = false }: Props) {
    const minSettlementAccounts = 2;
    const history = useHistory();
    const locationState = useLocation<LocationState>().state;
    const activeTemplate = templates.find(t => t.active);
    const { settlementAccounts, settlementAccountsRequestState } = useSettlementAccounts(false);
    const isLoading = !isRequestSuccess(settlementAccountsRequestState) || !isRequestSuccess(requestState);
    const templatePanelState = locationState?.panel?.type === PanelType.Templates;

    const handleClick = () => {
        if (templatePanelState) {
            history.replace(history.location.pathname, undefined);
        } else {
            onShow(new LocationStateBuilder().panel(PanelType.Templates).result());
        }
    }

    return (
        <>
            <OnHoverTooltip
                overlay="To use allocation templates, you must have at least two settlement accounts."
                disabled={isLoading || settlementAccounts.length >= minSettlementAccounts}
                placement={TooltipPlacement.BottomRight}
                wrapperClass="allocation-overlay-btn"
            >
                <button
                    className={cn('btn-link', {'btn-link-active': templatePanelState})}
                    disabled={isLoading || settlementAccounts.length < minSettlementAccounts || disabled}
                    onClick={handleClick}
                >
                    <IconSVG name="settlement-templates" width={16} height={16} /> Allocation Templates
                </button>
            </OnHoverTooltip>
            {!templates.length && <span className="template-label">No Templates</span>}
            {!!templates.length && activeTemplate == null && <span className="template-label">No active templates</span>}
            {!!templates.length && activeTemplate != null && <span className="template-label active-template">{activeTemplate.name}</span>}
        </>
    );
}
