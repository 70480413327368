import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { constants } from '../../constants';
import { formatUtils, moneyUtils, numericUtils } from '../../utils';
import { BidLevelDelta } from '../common/bidding/BidLevelDelta';
import { BidOnBehalfIcon } from '../bidding/seller/bid-on-behalf/BidOnBehalfIcon';
import { BwicProcessType, Process } from '../../types/models/Process';
import { AxedIcon } from '../bidding/common/AxedIcon';
import { FinalIcon } from '../bidding/common/FinalIcon';
import { BwicVisitorType } from '../../types/state/BiddingState';
import { BwicHistoryActionSelector } from '../../selectors';
import { BwicHistoryActionType } from '../../types/bwic-history/BwicHisoryActionType';

interface MessageProps {
    action: BwicHistoryActionSelector;
    containerClientRect: DOMRect | null;
    onRead: (referenceName: string) => void;
    visitorType: BwicVisitorType;
    isAllToAll: boolean;
    process: Process;
}

interface TradeDetailsPayload {
    size: number;
    price: number;
    ticker: string;
}

export const Message = ({ action, containerClientRect, onRead, visitorType, isAllToAll, process }: MessageProps) => {
    const _message = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!action.isRead && containerClientRect != null && _message.current !== null) {
            const messageClientRect = _message.current.getBoundingClientRect();
            if (messageClientRect.top < containerClientRect.bottom) {
                onRead(action.referenceName);
            }
        }
    }, [action.isRead, action.referenceName, containerClientRect, onRead])

    const renderActionContent = (action: BwicHistoryActionSelector) => {
        switch (action.actionType) {
            case BwicHistoryActionType.Scheduled: return <span>BWIC scheduled</span>;
            case BwicHistoryActionType.Bidding: return <span>BWIC opened, initial bid placement started</span>;
            case BwicHistoryActionType.AxedFinalChanged:
            case BwicHistoryActionType.DirectBid:
            case BwicHistoryActionType.NewBid: return renderNewBidMessage(action.payload);
            case BwicHistoryActionType.DirectFeedback:
            case BwicHistoryActionType.QuickFeedback: return renderFeedbackMessage(action);
            case BwicHistoryActionType.TradedAway: return renderTradedAwayMessage(action.payload);
            case BwicHistoryActionType.TradeSubmitted: return renderTradeMessage(action);
            case BwicHistoryActionType.TradeAffirmed: return renderTradeAffirmedMessage(action.payload);
            case BwicHistoryActionType.TradeRejected: return renderTradeRejectedMessage(action.payload);
            case BwicHistoryActionType.TradeCanceled: return renderTradeCanceledMessage(action.payload)
            case BwicHistoryActionType.ColorDistributionSend: return <span>BWIC color added</span>;
            case BwicHistoryActionType.Finished: return <span>BWIC completed</span>;
            case BwicHistoryActionType.Canceled: return <span>BWIC canceled</span>;
            case BwicHistoryActionType.OpenBiddingStage1Started: return <span>Stage 1 started</span>;
            case BwicHistoryActionType.OpenBiddingStage1Ended: return renderStage1Ended();
            case BwicHistoryActionType.OpenBiddingStage2Ended: return renderStage2Ended();
            case BwicHistoryActionType.PriceTalk: return renderPxTalkMessage(action);
            default: return null;
        };
    };

    const renderStage1Ended = () => {
        let message = 'Stage 2 started';

        if (process?.type === BwicProcessType.BestFootForward) {
            if (visitorType === BwicVisitorType.Seller) {
                message = 'Bidding is finished. Please submit your trades';
            } else if (visitorType === BwicVisitorType.BrokerDealer || visitorType === BwicVisitorType.Buyer) {
                message = 'Bidding is completed. No bids are accepted';
            }
        }

        return <span>{message}</span>
    }

    const renderStage2Ended = () => {
        let message = 'Stage 2 ended';
        if (visitorType === BwicVisitorType.BrokerDealer || visitorType === BwicVisitorType.Buyer) {
            message = 'Bidding is completed. No bids are accepted';
        } else if (visitorType === BwicVisitorType.Seller) {
            message = 'Bidding is finished. Please submit your trades';
        }

        return <span>{message}</span>;
    }

    const renderPxTalkMessage = (action: BwicHistoryActionSelector) => {
        if (action.payload) {
            if (action.payload.talk) {
                return <span> Px Talk: {action.payload.ticker} <span className="at">@</span>&nbsp;<span className="level">{action.payload.talk}</span></span>
            }
            return <span> Px Talk removed for: {action.payload.ticker}</span>
        }

        return null;
    };

    const renderTradeMessage = (action: BwicHistoryActionSelector) => {
        const { isMyMessage, payload } = action;
        return (
            <span>
                {isMyMessage && <label><span className="at">@</span>&nbsp;{getReceiverCode(payload)}</label>}
                Trading {formatTradeDetails(payload)}, please affirm
            </span>
        );
    }

    const renderTradeAffirmedMessage = (payload: TradeDetailsPayload) => <span>Trade affirmed: {formatTradeDetails(payload)}</span>

    const renderTradeRejectedMessage = (payload: TradeDetailsPayload) => <span>Trade rejected: {formatTradeDetails(payload)}</span>

    const renderTradeCanceledMessage = (payload: TradeDetailsPayload) => <span>Trade @&nbsp;{formatUtils.formatBid(payload.price)} canceled automatically</span>

    const formatTradeDetails = (payload: TradeDetailsPayload) => {
        const amount = moneyUtils.amountToMM(payload.size, true);
        const bid = formatUtils.formatBid(payload.price);

        return (
            <>
                {payload.ticker} {amount} <span className="at">@</span>&nbsp;<span className="level">{bid}</span>
            </>
        )
    }

    const renderTradedAwayMessage = (payload: { ticker: string, isTradedAway: string }) => {
        if (payload.isTradedAway) {
            return (
                <>
                    <span className="ticker">{payload.ticker}</span> <span>traded away</span>
                </>
            );
        }

        return null;
    }

    const renderFeedbackMessage = (action: BwicHistoryActionSelector) =>
        <span>
            {
                action.payload &&
                action.payload.onBehalf &&
                <BidOnBehalfIcon />
            }
            {action.isMyMessage && <label><span className="at">@</span>&nbsp;{getReceiverCode(action.payload)}</label>}
            {
                !!action.payload.ticker &&
                <>
                    {action.payload.ticker}
                    {!!action.payload.value && <> <span className="at">@</span>&nbsp;<span className="level">{formatUtils.formatBid(action.payload.value)}</span></>}
                    : {action.payload.feedback}
                </>

            }
            {!action.payload.ticker && action.payload.feedback}
        </span>

    const renderNewBidMessage = (payload: { onBehalf: boolean, pass: boolean, axed: boolean, final: boolean, value?: number, delta?: number, ticker: string }) =>
        <>
            {payload.onBehalf && <BidOnBehalfIcon />}
            <span className="ticker">{payload.ticker}</span> <span className="at">@</span>&nbsp;

            {payload.pass && <span className="pass">PASS</span>}
            {
                !payload.pass &&
                <>
                    <span className="level">{formatUtils.formatBid(payload.value)}</span>
                    {
                        numericUtils.isNumber(payload.value) &&
                        numericUtils.isNumber(payload.delta) &&
                        <BidLevelDelta
                            currentLevel={+payload.value - +payload.delta}
                            updatedLevel={+payload.value}
                            updatedLevelVisible={false}
                        />
                    }
                    {payload.axed && <span>{<AxedIcon />}</span>}
                    {payload.final && <span>{<FinalIcon />}</span>}
                </>
            }
        </>

    const getReceiverCode = (payload: { buyerPseudoOrderNumber?: number, receiverCode: string }) => {
        return (isAllToAll && visitorType === BwicVisitorType.Buyer) || !payload.buyerPseudoOrderNumber
            ? payload.receiverCode
            : `${payload.receiverCode}-${payload.buyerPseudoOrderNumber}`
    }

    const getDirectBidCompanyCode = (action: BwicHistoryActionSelector) => {
        const { buyerPseudoOrderNumber, code, receiverCode } = action.payload;
        const companyCode = code || receiverCode;

        if (!companyCode) {
            return '';
        }

        if (visitorType === BwicVisitorType.Buyer) {
            return companyCode;
        }
        if (companyCode && buyerPseudoOrderNumber) {
            return `${companyCode}-${buyerPseudoOrderNumber}`;
        }

    }

    const getDirectBidCompanyName = (action: BwicHistoryActionSelector) => {
        const { legalName, code, receiverCode, buyerPseudoOrderNumber } = action.payload;
        const companyName = legalName || code || receiverCode;

        if (!companyName) {
            return '';
        }

        if (visitorType === BwicVisitorType.Buyer) {
            return companyName;
        }

        if (buyerPseudoOrderNumber) {
            return `${companyName}-${buyerPseudoOrderNumber}`;
        }

    }


    const isBidOnBehalf =
        action.actionType === BwicHistoryActionType.NewBid &&
        !action.isResponseMessage &&
        !!action.payload.onBehalf;

    const messageCssClasses = classNames('history-message', {
        'bwic-common-message': !action.isMyMessage && !action.isResponseMessage,
        'my-message': action.isMyMessage,
        'response-message': action.isResponseMessage
    });

    const content = renderActionContent(action);

    return (
        <div ref={_message} className={messageCssClasses}>
            {
                content &&
                <div className="message-wrapper">
                    {isBidOnBehalf && <div className="message-name">{action.payload.onBehalf} - Bid on behalf</div>}
                    {
                        action.isResponseMessage &&
                        !isBidOnBehalf &&
                        <div className="message-name">
                            {action.submittedBy ? action.submittedBy.name : getDirectBidCompanyName(action)}
                            {
                                action.isResponseMessage &&
                                <span className="avatar">
                                    {action.submittedBy ? action.submittedBy.code : getDirectBidCompanyCode(action)}
                                </span>
                            }
                        </div>
                    }
                    <div className="message-section">
                        {!action.isRead && action.isResponseMessage && <span className="unread-message"></span>}

                        <div className="message-box">
                            <span className="message-content">
                                {content}
                            </span>
                            <span className="message-time">{moment(action.sentDateUtc).local().format(constants.timeFormat)}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
