import * as React from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';
import { TradeAllocationTemplate } from '../../../../types/trade-allocation/template/TradeAllocationTemplate';
import {
    PercentTradeAllocationTemplateItem,
    RatingTradeAllocationTemplateItem,
    TradeAllocationTemplateItem
} from '../../../../types/trade-allocation/template/TradeAllocationTemplateItem';
import { tradeAllocationTypeTitle } from '../../../../types/trade-allocation/TradeAllocationType';
import ContextMenu from '../../../controls/ContextMenu';
import { ContextMenuItem } from '../../../controls/ContextMenuItem';
import { formatUtils } from '../../../../utils/format.utils';
import { LocationStateBuilder, PanelType } from '../../../../types/state/ui/LocationState';
import { Confirm } from '../../../alerts';
import { OnHoverTooltip } from '../../../common';
import { roles } from '../../../../constants';

interface Props {
    template: TradeAllocationTemplate;
    disabled: boolean;
    onActiveChange: (temaplteId: number, active: boolean, lock: Date) => void;
    onDelete: (temaplteId: number) => void;
}

export function TemplateListItem({ template, disabled, onActiveChange, onDelete }: Props) {
    const history = useHistory();
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = React.useState(false);

    const formatValue = (item: TradeAllocationTemplateItem) => {
        if ((item as PercentTradeAllocationTemplateItem).percent) {
            return formatUtils.formatDecimal((item as PercentTradeAllocationTemplateItem).percent) + '%';
        } else if ((item as RatingTradeAllocationTemplateItem).rating) {
            return (item as RatingTradeAllocationTemplateItem).rating;
        }

        return null;
    }

    return (
        <div className={cn("templates-list-item", { "templates-list-item-active": template.active })}>
            <div className="templates-list-item-header flex-row">
                <h3>{template.name}</h3>
                <div className="flex-row flex-item-right">
                    {template.active && <span className="status active">Active</span>}
                    <ContextMenu>
                        <ContextMenuItem
                            disabled={disabled}
                            requiredRoles={[roles.SellerTrader]}
                            onClick={() => history.replace({
                                ...history.location,
                                state: new LocationStateBuilder()
                                    .panel(PanelType.Templates, { editTemplateId: template.id })
                                    .result()
                            })}
                        >
                            Edit
                        </ContextMenuItem>
                        {
                            template.active &&
                            <ContextMenuItem
                                disabled={disabled}
                                requiredRoles={[roles.SellerTrader]}
                                onClick={() => onActiveChange(template.id, false, template.lock)}
                            >
                                Deactivate
                            </ContextMenuItem>
                        }
                        {
                            !template.active &&
                            <ContextMenuItem
                                disabled={disabled}
                                requiredRoles={[roles.SellerTrader]}
                                onClick={() => onActiveChange(template.id, true, template.lock)}
                            >
                                Activate
                            </ContextMenuItem>
                        }
                        <ContextMenuItem
                            classNames="warning"
                            disabled={disabled}
                            sectionBlock={true}
                            requiredRoles={[roles.SellerTrader]}
                            onClick={() => setDeleteConfirmationVisible(true)}
                        >
                            Delete
                        </ContextMenuItem>
                    </ContextMenu>
                </div>
            </div>
            <div className="templates-list-item-type text-warm-grey">
                {tradeAllocationTypeTitle[template.type]}
            </div>
            <ul className="templates-list-item-data">
                {
                    template.items.map(i =>
                        <li key={i.id} className="templates-list-item-row flex-row">
                            <OnHoverTooltip wrapperClass="text-ellipsis" overlay={i.settlementAccount.accountName}>
                                <span>{i.settlementAccount.accountName}</span>
                            </OnHoverTooltip>
                            <span className="flex-item-right text-bold">{formatValue(i)}</span>
                        </li>
                    )
                }
            </ul>
            {
                deleteConfirmationVisible &&
                <Confirm
                    text={<>Would you like to remove <strong>{template.name}</strong> template?</>}
                    onConfirm={() => {
                        setDeleteConfirmationVisible(false);
                        onDelete(template.id);
                    }}
                    onCancel={() => setDeleteConfirmationVisible(false)}
                />
            }
        </div>
    );
}
