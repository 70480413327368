import { useHistory, useLocation } from 'react-router';
import { SettlementAccountsView } from '../../settlement/SettlementAccountsView';
import { routes } from '../../../constants/routes';
import { SettlementAccountView } from '../../settlement/view/SettlementAccountView';
import { LocationState, PanelType } from '../../../types/state/ui/LocationState';
import { TradeAllocationTemplate } from '../../../types/trade-allocation/template/TradeAllocationTemplate';
import { TradeAllocationTemplatePanel } from './template/TradeAllocationTemplatePanel';

interface Props {
    templates: TradeAllocationTemplate[];
}

export function BlotterSettlementAccount({ templates }: Props) {
    const history = useHistory();
    const locationState = useLocation<LocationState>().state;
    const templatePanelState = locationState?.panel?.type === PanelType.Templates
        ? locationState
        : undefined;


    return (
        <SettlementAccountsView
            onAccountChange={(account, replace) => {
                const path = routes.blotterSettlementAccountDetailsUrl(account.id);
                replace ? history.replace(path, templatePanelState) : history.push(path, templatePanelState);
            }}

        >
            {
                (activeSettlementAccount) => {
                    return (
                        <>
                            {
                                activeSettlementAccount &&
                                <SettlementAccountView settlementAccount={activeSettlementAccount} />
                            }
                            <TradeAllocationTemplatePanel templates={templates} />
                        </>
                    );
                }
            }
        </SettlementAccountsView>
    );
}
