import React from 'react';
import { Checkbox } from '../controls/Checkbox';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { TradeSide } from '../../types/trades/TradeSide';
import { Preloader } from '../common/Preloader';

interface Props {
    onClick: (tradeId: string, isBuyTrade: boolean, value: boolean) => void;
    bookedBuy: boolean;
    bookedSell: boolean;
    tradeSide: TradeSide;
    tradeId: string;
}

export const TradeBookedCheckbox: React.FC<Props> = ({ onClick, tradeId, tradeSide, bookedSell, bookedBuy }) => {
    const isBuyTrade = tradeSide === TradeSide.Buy;
    const isRequesting = useSelector((state: AppState) => state.blotter.requestStatusSetBooked[`${tradeId}_${isBuyTrade}`]);

    const handleClick = () => {
        if (!isRequesting) {
            onClick(tradeId, tradeSide === TradeSide.Buy, isBuyTrade ? !bookedBuy : !bookedSell)
        }
    };

    return (
        <Preloader small inProgress={isRequesting}>
            <Checkbox
                checked={isBuyTrade ? bookedBuy : bookedSell}
                onChange={handleClick}
            />
        </Preloader>
    )
};
