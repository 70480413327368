import * as React from 'react';
import { useHistory } from 'react-router';
import { sumBy, values } from 'lodash';
import cn from 'classnames';
import { TradeAllocationTemplate } from '../../../../types/trade-allocation/template/TradeAllocationTemplate';
import { Grid } from '../../../grid/Grid';
import RouteLeavingGuard from '../../../routing/RouteLeavingGuard';
import { RadioButton } from '../../../controls/RadioButton';
import { TradeAllocationType } from '../../../../types/trade-allocation/TradeAllocationType';
import { useSelector } from 'react-redux';
import { gridActions } from '../../../../actions';
import { errorMessages, gridColumns, constants } from '../../../../constants';
import { Ratings } from '../../../../types/enums/Rating';
import {
    EditTradeAllocationTemplateItem,
    PercentTradeAllocationTemplateItem,
    RatingTradeAllocationTemplateItem,
    TradeAllocationTemplateItem
} from '../../../../types/trade-allocation/template/TradeAllocationTemplateItem';
import { AppState } from '../../../../types/state/AppState';
import { GridColumn, GridDataItem } from '../../../../types/state/GridState';
import { formatUtils, isRequesting, isRequestSuccess, numericUtils, stringUtils } from '../../../../utils';
import { FormFieldLabel } from '../../../forms';
import { SettlementAccount } from '../../../../types/settlement/SettlementAccount';
import { blotterTradeAllocationTemplateActions } from '../../../../actions/blotter-trade-allocation-template.actions';
import { FormError } from '../../../controls';
import { LocationState, LocationStateBuilder, PanelType } from '../../../../types/state/ui/LocationState';
import { StatusMessageSection } from '../../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../../types/state/NotificationState';
import { Location } from 'history';
import { useAppDispatch } from '../../../../effects/useAppDispatch';

interface Props {
    template?: TradeAllocationTemplate;
    settlementAccounts: SettlementAccount[];
    existTemplateNames: string[];
}

export function TemplateEdit({ template, existTemplateNames, settlementAccounts }: Props) {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const dataItems: GridDataItem<EditTradeAllocationTemplateItem>[] = useSelector((s: AppState) => s.grid.dataItems);
    const saveRequestState = useSelector((s: AppState) => s.blotter.tradeAllocationTemplate.saveRequestState);

    const [editTemplateName, setEditTemplateName] = React.useState(template?.name ?? '');
    const [editTemplateNameError, setEditTemplateNameError] = React.useState('');
    const [editTempateType, setEditTemplateType] = React.useState(template?.type ?? TradeAllocationType.PercentOfTrade);
    const [percentError, setPercentError] = React.useState(false);

    const editDataItems = dataItems.filter(i => !i.draft);
    const percent = editTempateType === TradeAllocationType.PercentOfTrade
        ? sumBy(editDataItems, i => numericUtils.numberOrDefault(i.percent))
        : 100;
    const hasErrors =
        percentError ||
        Boolean(editTemplateNameError) ||
        editDataItems.some(i => i.errors && values(i.errors).length);

    const hasChanges = () => {
        const isChangedOrRemoved = (
            original: TradeAllocationTemplateItem,
            edit?: EditTradeAllocationTemplateItem) => {
            if (!edit) return true;

            return (
                original.settlementAccount.id !== Number(edit.settlementAccountId) || (
                    editTempateType === TradeAllocationType.PercentOfTrade &&
                    (original as PercentTradeAllocationTemplateItem).percent !== Number(edit.percent)
                ) || (
                    editTempateType === TradeAllocationType.CreditRatings &&
                    (original as RatingTradeAllocationTemplateItem).rating !== edit.rating
                )
            );
        }

        return (
            editTemplateName.trim() !== (template?.name ?? '') ||
            editTempateType !== (template?.type ?? TradeAllocationType.PercentOfTrade) ||
            editDataItems.length !== (template?.items.length ?? 0) ||
            editDataItems.some(i => !i.id) ||
            (template?.items ?? []).some(i => isChangedOrRemoved(i, editDataItems.find(e => e.id === i.id)))
        );
    }

    const validateTemplateName = (name: string) => {
        if (stringUtils.isNullOrWhiteSpace(name)) {
            return errorMessages.empty;
        } else if (existTemplateNames.some(n => n === name.trim())) {
            return "Template with the same name already exists";
        }

        return '';
    }

    const saveDisabled =
        hasErrors ||
        stringUtils.isNullOrWhiteSpace(editTemplateName) ||
        !editDataItems.length ||
        isRequesting(saveRequestState) ||
        !hasChanges() || (
            editTempateType === TradeAllocationType.CreditRatings &&
            !editDataItems.some(i => i.settlementAccountId)
        );

    React.useEffect(() => {
        if (percentError && percent === 100) {
            setPercentError(false);
        }
    }, [percent, percentError]);

    React.useEffect(() => {
        return () => {
            dispatch(gridActions.reset());
        }
    }, [dispatch])

    React.useEffect(() => {
        const settlementAccount: GridColumn = {
            ...gridColumns.settlementAccount,
            type: "select",
            selectSourceItemsCallback: () => settlementAccounts.map(a => ({
                key: a.id, title: a.accountName
            }))
        };

        const columns: GridColumn[] = editTempateType === TradeAllocationType.PercentOfTrade
            ? [settlementAccount, { ...gridColumns.percentOfTrade, type: 'number' }]
            : [
                { ...settlementAccount, unique: false, required: false, keepEmptyOption: true, format: (value?: number | string) => value ? String(value) : constants.emptyPlaceholder },
                { ...gridColumns.rating, type: 'select', readonly: true, required: false, disabledSort: true, title: 'Rtg' }
            ];
        const rowLimit = editTempateType === TradeAllocationType.PercentOfTrade
            ? 20
            : Ratings.length;

        let dataItems: EditTradeAllocationTemplateItem[] = [];

        if (editTempateType === TradeAllocationType.PercentOfTrade &&
            template &&
            template.type === TradeAllocationType.PercentOfTrade) {
            dataItems.push(
                ...template.items.map(i => ({
                    id: i.id,
                    settlementAccountId: i.settlementAccount.id,
                    percent: (i as PercentTradeAllocationTemplateItem).percent
                }))
            );
        } else if (editTempateType === TradeAllocationType.CreditRatings) {
            dataItems.push(...Ratings.map(rating => ({
                settlementAccountId: template?.type === TradeAllocationType.CreditRatings
                    ? template?.items.find(ti => (ti as RatingTradeAllocationTemplateItem).rating === rating)?.settlementAccount.id
                    : undefined,
                    rating
            })));
        }

        dispatch(gridActions.reset());
        dispatch(gridActions.init(dataItems, columns, rowLimit));

    }, [editTempateType, template, settlementAccounts, dispatch]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditTemplateName(e.target.value);
        if (editTemplateNameError) {
            const error = validateTemplateName(e.target.value);
            setEditTemplateNameError(error);
        }
    };

    const handleCancel = () => {
        history.replace({
            ...history.location,
            state: new LocationStateBuilder().panel(PanelType.Templates).result()
        });
    };

    const handleSave = (activate: boolean) => {
        const nameError = validateTemplateName(editTemplateName);
        setEditTemplateNameError(nameError);

        dispatch(gridActions.validate());

        const percentError = percent !== 100;
        setPercentError(percentError);

        if (!nameError && !percentError) {
            dispatch(
                blotterTradeAllocationTemplateActions.save(
                    template?.id ?? 0,
                    editTemplateName,
                    editTempateType,
                    activate,
                    template?.lock
                ));
        };
    }

    const renderGridFooter = () => {
        if (editTempateType === TradeAllocationType.PercentOfTrade) {
            return (
                <tr>
                    <td className="cell-no-data"></td>
                    <td className="cell-no-data cell-total">
                        <span className="text-bold">Total</span>
                    </td>
                    <td className="cell-settlementAccountId"></td>
                    <td className="cell-percent">{formatUtils.formatDecimal(percent, 2)}% / <span className="text-bold">100%</span></td>
                    <td className="cell-no-data cell-no-data-end"></td>
                </tr>
            );
        }

        return null;
    }

    return (
        <div className="allocation-templates">
            <FormFieldLabel required={true} text="Template Name" />
            <div className="form-control-wrapper template-title">
                <input
                    className="form-control form-control-sm"
                    type="text"
                    value={editTemplateName}
                    maxLength={64}
                    placeholder="Template Name"
                    onChange={handleNameChange}
                />
                <FormError message={editTemplateNameError} />
            </div>
            <div className="radio-group template-type">
                <RadioButton
                    name="template-type"
                    label="% of Trade"
                    value={TradeAllocationType.PercentOfTrade}
                    checked={editTempateType === TradeAllocationType.PercentOfTrade}
                    onChange={() => setEditTemplateType(TradeAllocationType.PercentOfTrade)}
                />
                <RadioButton
                    name="template-type"
                    label="Credit Ratings"
                    value={TradeAllocationType.CreditRatings}
                    checked={editTempateType === TradeAllocationType.CreditRatings}
                    onChange={() => setEditTemplateType(TradeAllocationType.CreditRatings)}
                />
            </div>
            <div className={cn("trade-allocation", { "trade-allocation-rating": editTempateType === TradeAllocationType.CreditRatings })}>
                <Grid
                    dataUploadDisabled={true}
                    deleteDisabled={editTempateType === TradeAllocationType.CreditRatings}
                    addRowVisible={false}
                    moveRowVisible={false}
                    renderFooter={renderGridFooter}
                />
                {
                    percentError &&
                    <StatusMessageSection type={StatusMessageSectionType.Error}>
                        Entered total % of trade should equal 100.
                    </StatusMessageSection>
                }
            </div>
            <div className="trade-allocation-buttons flex-row justify-content-end">
                <button
                    className="btn btn-sm btn-ghost"
                    disabled={isRequesting(saveRequestState)}
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-sm btn-ghost"
                    disabled={saveDisabled}
                    onClick={() => handleSave(false)}
                >
                    Save
                </button>
                <button
                    className="btn btn-sm btn-main"
                    disabled={saveDisabled}
                    onClick={() => handleSave(true)}
                >
                    Save & activate
                </button>
            </div>
            <RouteLeavingGuard
                navigate={(pathname: string, search?: string, state?: any) =>
                    history.replace({ pathname, search, state })
                }
                shouldBlockNavigation={(nextLocation: Location<LocationState<{ editTemplateId?: number }>>) => {
                    return (
                        !isRequesting(saveRequestState) &&
                        !isRequestSuccess(saveRequestState) && !(
                            nextLocation?.state?.panel?.type === PanelType.Templates &&
                            nextLocation?.state?.panel?.payload?.editTemplateId === (template?.id ?? 0)
                        ) &&
                        hasChanges()
                    )
                }}
            />
        </div>
    );
}
