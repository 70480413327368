import { useSelector } from 'react-redux';
import { bwicHistoryActions } from '../../actions';
import { RightSidePanel } from '../controls';
import { MessageHistory } from './MessageHistory';
import { AppState } from '../../types/state/AppState';
import { Process } from '../../types/models/Process';
import { useAppDispatch } from '../../effects/useAppDispatch';


export function MessageHistoryPanel({ process, isAllToAll }: { process: Process, isAllToAll: boolean }) {
    const dispatch = useAppDispatch();
    const ticker = useSelector((store: AppState) => store.bwicHistory.ticker);
    const visible = useSelector((store: AppState) => store.bwicHistory.visible);

    if (visible) {
        return (
            <RightSidePanel
                className="right-panel-history"
                title={ticker ? `History - ${ticker}` : 'History'}
                onClose={() => dispatch(bwicHistoryActions.showHistory(false))}
            >
                <MessageHistory
                    process={process}
                    isAllToAll={isAllToAll}
                />
            </RightSidePanel>
        );
    }

    return null;
}
