import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import cn from "classnames";
import { TradeSettlementStatus } from '../../../types/settlement/TradeSettlementStatus';
import { user } from '../../../user';
import { roles, routes } from '../../../constants';
import { Confirm } from '../../alerts';
import { SettlementAccount } from '../../../types/settlement/SettlementAccount';
import { notificationActions } from '../../../actions';
import { BlotterSearchResult } from '../../../types/blotter/BlotterSearchResult';
import { LocationState, LocationStateBuilder, PanelType, PopupType } from '../../../types/state/ui/LocationState';
import IconSVG from "../../../styles/svg-icons";
import { FeatureButton } from '../../access/FeatureButton';
import { ContextMenu } from '../../controls';
import { ContextMenuItem } from '../../controls/ContextMenuItem';
import { TradeSide } from '../../../types/trades/TradeSide';
import { TradeAllocationPopupLocationPayload } from './TradeAllocationPopup';
import { CompanySlim } from '../../../types/company/CompanySlim';
import { RoleActionBlocker } from '../../access/RoleActionBlocker';

interface Props {
    trade: BlotterSearchResult;
    settlementAccounts: SettlementAccount[];
}

export function TradeAllocationButton({ trade, settlementAccounts }: Props) {
    const history = useHistory<LocationState<any, TradeAllocationPopupLocationPayload>>();

    const handleClick = (companyId: number, side: TradeSide) => {
        let locationStateBuilder = new LocationStateBuilder(history.location.state)
            .popup(
                PopupType.TradeAllocationPopup,
                { tradeAllocationTradeId: trade.tradeId, companyId, side }
            );

        const isTemplatePanelEdit =
            history.location.state?.panel?.type === PanelType.Templates &&
            history.location.state?.panel?.payload?.editTemplateId != null;

        if (isTemplatePanelEdit) {
            // Reset TradeAllocationPanel edit state
            locationStateBuilder.panel(history.location.state!.panel!.type);
        }

        history.replace({ ...history.location, state: undefined }, locationStateBuilder.result());
    }

    return user.hasRoles(...roles.seller())
        ? <SellerTradeAllocationButton trade={trade} settlementAccounts={settlementAccounts} onClick={handleClick} />
        : <DealerTradeAllocationButton trade={trade} settlementAccounts={settlementAccounts} onClick={handleClick} />
}

interface RoleTradeAllocationButtonProps extends Props {
    onClick: (companyId: number, side: TradeSide) => void;
}

function SellerTradeAllocationButton({ trade, settlementAccounts, onClick }: RoleTradeAllocationButtonProps) {
    const dispatch = useDispatch();
    const settlementStatus = trade.directBuyerCompany ? trade.buyerSettlementStatus : trade.sellerSettlementStatus

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        const companyId: number = user.current()!.companyId;
        const accounts = settlementAccounts.filter(a => a.assetManager.id === companyId);

        if (accounts.length) {
            const side = trade.directBuyerCompany ? TradeSide.Sell : TradeSide.Buy;
            onClick(companyId, side);
        } else {
            dispatch(notificationActions.warningModal(
                "Settlement Account is not created",
                `You don’t have any settlement accounts yet. Please contact ${trade.counterparty.name} company for more details.`
            ));
        }
    }

    const renderButton = () => {
        switch (settlementStatus) {
            case TradeSettlementStatus.PendingAllocation:
            case TradeSettlementStatus.PendingProceeds:
            case TradeSettlementStatus.PendingSettlement:
                return (
                    <FeatureButton className="btn btn-sm btn-ghost" onClick={handleClick}>
                        <IconSVG name="edit-pencil" width="16" height="16" />
                        <span>Allocation</span>
                    </FeatureButton>
                );
            default: return null;
        }
    }

    return (
        <RoleActionBlocker roles={[roles.SellerTrader]}>
            {renderButton()}
        </RoleActionBlocker>
    );
}

function DealerTradeAllocationButton({ trade, settlementAccounts, onClick }: RoleTradeAllocationButtonProps) {
    const history = useHistory<LocationState<any, { tradeAllocationTradeId: string }>>();
    const [noSettlementAccountCompany, setNoSettlementAccountCompany] = React.useState<CompanySlim | undefined>();
    const [expanded, setExpanded] = React.useState(false);
    const isClearingBank = user.current()?.isClearingBank;

    const handleClick = (side: TradeSide) => {
        const company = side === TradeSide.Sell
            ? trade.directBuyerCompany!
            : trade.counterparty;

        const accounts = settlementAccounts.filter(a => a.assetManager.id === company.id);

        if (accounts.length) {
            onClick(company.id, side);
        } else {
            setNoSettlementAccountCompany(company);
        }
    }

    const getSellTradeText = () => {
        switch (trade.buyerSettlementStatus) {
            case TradeSettlementStatus.PendingAllocation:
                return "Add Allocation for Sell Trade";
            case TradeSettlementStatus.PendingSettlement:
                return "Edit Allocation for Sell Trade";
            case TradeSettlementStatus.PendingProceeds:
                return "Add Proceeds for Sell Trade";
            default: return '';
        }
    }

    const getBuyTradeText = () => {
        switch (trade.sellerSettlementStatus) {
            case TradeSettlementStatus.PendingAllocation:
                return "Add Allocation for Buy Trade";
            case TradeSettlementStatus.PendingSettlement:
                return "Edit Allocation for Buy Trade";
            case TradeSettlementStatus.PendingProceeds:
                return "Add Proceeds for Buy Trade";
            default: return '';
        }
    }

    const sellText = getSellTradeText();
    const buyText = getBuyTradeText();

    if (!sellText && !buyText) return null;

    return (
        <>
            <ContextMenu
                disabled={!isClearingBank}
                onShow={() => setExpanded(true)}
                onHide={() => setExpanded(false)}
                customTargetComponent={
                    <button
                        disabled={!isClearingBank}
                        className={cn('btn btn-sm btn-ghost btn-options', { 'show': expanded })}
                    >
                        <IconSVG name="edit-pencil" width="16" height="16" />
                        <span>Allocation</span>
                        <IconSVG name="icon-expand" width="16" height="16" />
                    </button>
                }
            >
                <ContextMenuItem
                    disabled={!isClearingBank}
                    onClick={e => { e.stopPropagation(); handleClick(TradeSide.Buy) }}
                >
                    {buyText}
                </ContextMenuItem>
                <ContextMenuItem
                    disabled={!isClearingBank}
                    onClick={e => { e.stopPropagation(); handleClick(TradeSide.Sell) }}
                >
                    {sellText}
                </ContextMenuItem>
            </ContextMenu>
            {
                noSettlementAccountCompany != null &&
                <div onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}>
                    <Confirm
                        title="Settlement Account is not created"
                        text={<>Client <span className="text-medium">{noSettlementAccountCompany.name}</span> doesn't have any settlement accounts yet. Would you like to create?</>}
                        confirmButtonText="Create"
                        onConfirm={() => history.push(routes.createClientsSettlementAccountUrl(noSettlementAccountCompany.id))}
                        onCancel={() => setNoSettlementAccountCompany(undefined)}
                    />
                </div>
            }
        </>
    )
}
