import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { bwicHistoryActions } from '../../actions';
import IconSVG from '../../styles/svg-icons';
import { FeatureButton } from '../access/FeatureButton';
import { ShowFor } from '../access';
import { roles } from '../../constants';
import { user } from '../../user';
import { AppState } from '../../types/state/AppState';
import { RoleActionBlocker } from '../access/RoleActionBlocker';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface HistoryButtonProps {
    positionId?: number;
    ticker?: string;
    text?: string;
    unreadCountVisble?: boolean;
    disabled?: boolean;
    onClick?: (active: boolean) => void
}

export function HistoryButton({ positionId, ticker = '', text = '', unreadCountVisble = true, disabled = false, onClick }: HistoryButtonProps) {
    const dispatch = useAppDispatch();
    const history = useSelector((s: AppState) => s.bwicHistory);
    const historyActions = (positionId ? history.actions?.filter(a => a.positionId === positionId) : history.actions) ?? [];
    const unreadCount =
        unreadCountVisble &&
        user.hasRoles(roles.SellerTrader, roles.BrokerDealerTrader) &&
        historyActions.filter(a => !a.isRead).length;
    const isActive = history.visible && history.positionId === positionId;

    function handleClick(e: React.MouseEvent) {
        dispatch(bwicHistoryActions.showHistory(!isActive, positionId, ticker));
        e.stopPropagation();
        onClick?.(!isActive);
    }

    function renderButton() {
        return (
            <FeatureButton
                className={classNames('btn-link btn-history', { 'btn-link-active': isActive, 'short': !text })}
                onClick={handleClick}
                disabled={disabled}
            >
                <IconSVG name="timeline" width={16} height={16} /> {text}
                {!!unreadCount && <span className="badge">{unreadCount}</span>}
            </FeatureButton>
        );
    }

    return (
        <>
            <ShowFor roles={roles.seller()}>
                {renderButton()}
            </ShowFor>
            <ShowFor roles={roles.bd()}>
                <RoleActionBlocker roles={[roles.BrokerDealerTrader]}>
                    {renderButton()}
                </RoleActionBlocker>
            </ShowFor>
        </>
    );
}


HistoryButton.defaultProps = {
    positionId: null,
    onClick: null
}