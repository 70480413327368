import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ImageSVG from '../../styles/svg';
import { bwicHistoryActions } from '../../actions';
import { arrayUtils, isRequesting } from '../../utils';
import { constants, roles } from '../../constants';
import { Message } from './Message';
import { getHistory } from '../../selectors';
import { Preloader } from '../common';
import { ShowFor } from '../access';
import { BwicHistoryActionType } from '../../types/bwic-history/BwicHisoryActionType';
import { RequiredRole } from '../access/RequiredRole';

class MessageHistory extends React.Component {
    awaitingReadMessages = [];
    readMessages = [];
    firstUnreadMessageIndex = -1;
    state = {
        scrollableContainerClientRect: null
    }

    componentDidMount = () => {
        if (this.state.scrollableContainerClientRect == null && this._scrollableContainer != null) {
            this.awaitingReadMessages = [];
            this.readMessages = [];
            this.firstUnreadMessageIndex = this.getFirstUnreadActionIndex();
            this.setState({ scrollableContainerClientRect: this._scrollableContainer.getBoundingClientRect() });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.firstUnreadMessageIndex < 0) {
            const previousActions = prevProps.history.actions || [];
            const currentActions = this.props.history.actions || [];
            if (currentActions.length !== previousActions.length) {
                this.firstUnreadMessageIndex = this.getFirstUnreadActionIndex();
            }
        }
    }

    getFirstUnreadActionIndex = () => {
        return this.props.history.actions && this.props.history.actions.findIndex(a => !a.isRead);
    }

    handleContainerScroll = e => this.setState({ scrollableContainerClientRect: e.target.getBoundingClientRect() });

    handleMessageRead = referencName => {
        if (!this.awaitingReadMessages.some(m => m === referencName) &&
            !this.readMessages.some(m => m === referencName)) {
            this.awaitingReadMessages.push(referencName);
            setTimeout(() => this.markMessageRead(referencName), 1000);
        }
    }

    markMessageRead = referenceName => {
        if (this.awaitingReadMessages[this.awaitingReadMessages.length - 1] === referenceName) {
            this.readMessages.push(...this.awaitingReadMessages);
            this.props.dispatch(bwicHistoryActions.markHistoryMessagesAsRead(this.awaitingReadMessages));
            this.awaitingReadMessages = [];
        }
    }

    isTradeAwayCancelation = action => action.actionType === BwicHistoryActionType.TradedAway && !action.payload.isTradedAway;

    renderEmptyContent = () =>
        <div className="message-history-placeholder">
            <ImageSVG name="NoMessages" width={216} height={122} />
            <p>BWIC message history will be shown here</p>
        </div>

    renderActionItems = historyActions => {
        const hasReadMessages = historyActions.some(a => a.isRead);
        const actionsByDate = arrayUtils
            .groupBy(
                historyActions,
                action => moment(action.sentDateUtc)
                    .startOf('day')
                    .format(constants.dateShortFormat)
            );

        return Array
            .from(actionsByDate, ([key, value]) => ({ key, value }))
            .map(group => this.renderActionGroup(group, hasReadMessages));
    }

    renderActionGroup = (group, hasReadMessages) => {
        const result = [];

        result.push(this.renderDate(group.key));
        result.push(group.value.map((action, index) => {
            const shouldRenderUnreadLine =
                hasReadMessages &&
                this.firstUnreadMessageIndex >= 0 &&
                this.props.history.actions.indexOf(action) === this.firstUnreadMessageIndex &&
                !this.isTradeAwayCancelation(action);

            return (
                <div key={index} className="flex-none">
                    {shouldRenderUnreadLine && this.renderUnreadMessagesLine()}
                    <Message
                        action={action}
                        containerClientRect={this.state.scrollableContainerClientRect}
                        process={this.props.process}
                        visitorType={this.props.visitorType}
                        onRead={this.handleMessageRead}
                        isAllToAll={this.props.isAllToAll}
                    />
                </div>
            );
        }));

        return result;
    }

    renderDate = groupDate => {
        let text = groupDate;

        const date = moment(groupDate, constants.dateShortFormat);
        const today = new Date();

        if (date.isSame(today, 'day')) {
            text = 'Today';
        } else if (date.clone().add(1, 'days').isSame(today, 'day')) {
            text = 'Yesterday';
        }

        return <div className="message-history-date" key={groupDate}>{text}</div>
    }

    renderUnreadMessagesLine = () =>
        <div className="unread-notifications">
            <span>Unread notifications</span>
        </div>

    renderContent = history => {
        const hasHistoryItems = !!history.actions.length;

        return (
            <>
                <div>{hasHistoryItems && this.renderActionItems(history.actions)}</div>
                {!hasHistoryItems && this.renderEmptyContent()}
            </>
        );
    }

    render = () => {
        const { history } = this.props;

        if (!history.visible) {
            return null;
        }

        if (!history.actions) {
            return <Preloader inProgress={true} />
        }

        return (
            <div
                className="message-history"
                ref={node => this._scrollableContainer = node}
                onScroll={this.handleContainerScroll}
            >
                <Preloader inProgress={isRequesting(history.requestState)}>
                    <ShowFor roles={roles.seller()}>
                        {this.renderContent(history)}
                    </ShowFor>
                    <ShowFor roles={roles.bd()}>
                        <RequiredRole requiredRole={roles.BrokerDealerTrader}>
                            {this.renderContent(history)}
                        </RequiredRole>
                    </ShowFor>
                </Preloader>
            </div>
        );
    }
}

const mapStateToProps = ({ bwicHistory }) => ({
    visitorType: bwicHistory.visitorType,
    history: getHistory(bwicHistory)
});

const connectedMessageHistory = connect(mapStateToProps)(MessageHistory);
export { connectedMessageHistory as MessageHistory };
