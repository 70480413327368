import * as React from 'react';
import cn from 'classnames';
import { TableColumnStickType } from '../bidding/common/table/types/TableColumnStickType';
import { useTradeAllocation } from '../../effects/data-accessors/useTradeAllocation';
import { Preloader } from '../common';
import { formatUtils, isRequestSuccess, moneyUtils } from '../../utils';
import { StickyTableRow } from '../bidding/common/table/sticky/StickyTableRow';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { TradeAllocationItem } from '../../types/trade-allocation/TradeAllocationItem';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { SecurityListHeader } from '../bidding/common';
import { constants } from '../../constants';
import { TradeSide } from '../../types/trades/TradeSide';

interface Props {
    tradeId: string;
    columnType: TableColumnStickType;
    collapseLevel: number;
    side: TradeSide;
}

export function TradeAllocationTableRows({ tradeId, columnType, collapseLevel, side }: Props) {
    const { requestState, tradeAllocationItems } = useTradeAllocation(tradeId, columnType !== TableColumnStickType.Middle);

    const columnDefinitions: IColumnDefinition<TradeAllocationItem>[] =
        columnType === TableColumnStickType.Right
            ? [{
                columnKey: 'action',
                renderColumnHeaderContent: () => null,
                renderColumnContent: () => null,
                headerClassName: "",
                bodyClassName: ""
            }]
            : [{
                columnKey: "settlementAccout",
                renderColumnHeaderContent: () => "Settlement Account",
                renderColumnContent: item => item.settlementAccount.accountName,
                headerClassName: "data-list-cell-xxl",
                bodyClassName: "data-list-cell-xxl"
            },{
                columnKey: "miraeAccount",
                renderColumnHeaderContent: () => "Mirae Acc #",
                renderColumnContent: item => item.settlementAccount.miraeAcc ? item.settlementAccount.miraeAcc : constants.emptyPlaceholder,
                headerClassName: "data-list-cell-md",
                bodyClassName: "data-list-cell-md"
            }, {
                columnKey: "percentOfTrade",
                renderColumnHeaderContent: () => "% of Trade",
                renderColumnContent: item => formatUtils.formatDecimal(item.percent),
                headerClassName: "text-right",
                bodyClassName: "text-right"
            }, {
                columnKey: "amountOfTrade",
                renderColumnHeaderContent: () => "Amount of Trade",
                renderColumnContent: item => moneyUtils.money(item.amount, true, 3),
                headerClassName: "data-list-cell-md text-right",
                bodyClassName: "data-list-cell-md text-right"
            }
            ];

    if (side === TradeSide.Sell && columnType === TableColumnStickType.Middle) {
        const proceeds: IColumnDefinition<TradeAllocationItem> = {
            columnKey: "proceeds",
            renderColumnHeaderContent: () => "Proceeds",
            renderColumnContent: item => item.proceed ? moneyUtils.money(item.proceed, true, 3) : constants.emptyPlaceholder,
            headerClassName: "data-list-cell-sm text-right",
            bodyClassName: "data-list-cell-sm text-right"
        };

        columnDefinitions.push(proceeds);
    }

    const columns = columnDefinitions.map(c => new ColumnBuilder(c));
    const className = cn({ [`collapsed-content-level-${collapseLevel}`]: true });

    return (
        <div className="trade-allocation-table-content">
            <Preloader small={true} inProgress={!isRequestSuccess(requestState)}>
                {
                    isRequestSuccess(requestState) &&
                    <>
                        <SecurityListHeader className={className}>
                            {columns.map(c => c.renderHeader())}
                        </SecurityListHeader>
                        {
                            tradeAllocationItems
                                .filter(a => a.side === side)
                                .map(a =>
                                    <StickyTableRow
                                        key={`allocation-${a.id}`}
                                        rowKey={`allocation-${a.id}`}
                                        className={className}
                                        columns={columns}
                                        dataItem={a}
                                        context={{}}
                                    />
                                )
                        }
                    </>
                }
            </Preloader>
        </div>
    );
}
