import * as React from 'react';
import { useDispatch } from 'react-redux';
import { tradeAllocationTemplateActions } from '../../../../actions/trade-allocation-template.actions';
import { notificationActions } from '../../../../actions/notification.actions';
import { errorMessages } from '../../../../constants/error.messages';
import { RequestState } from "../../../../constants/request-state";
import { tradeAllocationTemplateService } from '../../../../services/trade-allocation-template.service';
import { errorActions } from '../../../../actions/error.actions';

export function useTempateActiveChange() {
    const dispatch = useDispatch();
    const [requestState, setRequestState] = React.useState(RequestState.none);

    const onActiveChange = async (templateId: number, active: boolean, lock: Date) => {
        try {
            setRequestState(RequestState.request);
            const updates = await tradeAllocationTemplateService.setTemplateActive(templateId, active, lock);
            setRequestState(RequestState.success);

            const activated = updates.find(t => t.active);
            const deactivated = updates.find(t => !t.active);

            dispatch(tradeAllocationTemplateActions.activeChange(activated, deactivated));
        } catch (e) {
            setRequestState(RequestState.failure);
            if (e.status === 409) {
                dispatch(
                    notificationActions.notificationAddErrorMessageModal(
                        errorMessages.refreshPage,
                        "Can't activate Trade Allocation Template",
                        true
                    )
                );
            } else {
                dispatch(errorActions.unexpectedError(e));
            }
        }
    };

    return { activateRequestState: requestState, onActiveChange };
}
